'use strict';

var tile = require('core/product/tile');

tile.mouseEnter = function() {
    $('body').on('mouseenter', '.product-tile .swatch:not(.unselectable)', function(e) {
        var $swatch = $(this);
        module.exports.methods.swatchSwitch($swatch);
    });
};

tile.saveScrollPosition = function() {
    var plpScrollPos = {
        pos: 0,
        history: 0
    };
    $('body').on('click', '.product-tile', function(e) {
        plpScrollPos.pos = $(window).scrollTop();
        localStorage.setItem('plpScrollPos', JSON.stringify(plpScrollPos));
    });
}

module.exports = tile;
