'use strict';
const menu = require('core/components/menu');
const header = require('core/components/header');

// These are the selectors for the click events
const accordion = document.querySelector('#mobileSearchAccordion');
const mobileResetButton = document.querySelector('.mobile-reset-button');
const mobileSearchBar = document.querySelector('.mobile-search-field');
const mobileSearchButton = document.querySelector('.mobile-search-button');
const mobileSiteSearch = document.querySelector('.mobile-site-search');
const navbarToggler = document.querySelector('.navbar-toggler');

function hideElement(element) {
    element.classList.remove('d-block');
    element.classList.add('d-none');
};

function showElement(element) {
    element.classList.remove('d-none');
    element.classList.add('d-block');
};

if (mobileSearchButton) {
    mobileSearchButton.addEventListener('click', event => {
        hideElement(event.target);
        showElement(mobileSearchBar);
        showElement(mobileResetButton);
        showElement(mobileSiteSearch);

        menu.toggleDrawer('close');
    });
}

if (mobileResetButton) {
    mobileResetButton.addEventListener('click', event => {
        hideElement(event.target);
        hideElement(mobileSearchBar);
        showElement(mobileSearchButton);
        hideElement(mobileSiteSearch);

        mobileSearchBar.value = '';
    });
}

if (navbarToggler) {
    navbarToggler.addEventListener('click', () => {
        hideElement(mobileResetButton);
        showElement(mobileSearchButton);

        accordion.classList.remove('show');
    });
}

module.exports = header;