'use strict';

/**
 * Show a spinner inside a given element
 * @param {element} $target - Element to block by the veil and spinner.
 *                            Pass body to block the whole page.
 */
function addSpinner($target) {
    var $veil = $(`<div class="veil"><div class="underlay">`);
    $veil.append(`
    <svg class="hex-loader" width="100%" height="100%" viewBox="0 0 200 200" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:1;">
  <g transform="matrix(1.13546,0,0,.98,-7.7723,-0.01)">
        <path stroke-width="10" stroke="#CCCCCC" d="M94.915,0.003L162.436,33.828L162.436,159.468L94.915,193.293L25.633,159.468L25.633,33.828L94.915,0.003Z"/>
    </g>
    <g transform="matrix(1.13546,0,0,.98,-7.7723,-0.01)">
        <path class="hex-path" stroke-width="7" stroke="#000000" d="M94.915,0.003L162.436,33.828L162.436,159.468L94.915,193.293L25.633,159.468L25.633,33.828L94.915,0.003Z" style="fill:white;"/>
    </g>
    <g id="icon-the-original" transform="matrix(6.35789,0,0,6.35789,94.7786,-12.0601)">
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M11.17,17.093L11.612,18.215L12.616,18.589L13.339,17.854L13.554,16.291L12.844,16.344L12.71,17.347L12.415,17.641L12.04,17.52L11.866,17.039L12.429,13.017L12.723,12.709L13.085,12.843L13.272,13.324L13.125,14.326L13.835,14.259L13.969,13.297L13.527,12.148L12.509,11.774L11.799,12.509L11.156,17.052L11.17,17.093Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M8.947,18.563L8.197,18.282L8.317,17.467L7.915,17.32L7.768,18.429L8.906,18.843L8.947,18.563Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M5.102,14.968L4.7,14.834L5.397,9.93L5.584,16.09L6.053,16.264L7.848,10.825L7.151,15.716L6.763,15.583L6.642,16.478L8.116,17.013L8.236,16.117L7.808,15.957L8.692,9.662L9.12,9.809L9.254,8.914L7.848,8.406L6.12,13.966L6.04,7.751L4.634,7.243L4.513,8.139L4.942,8.299L4.058,14.594L3.629,14.433L3.495,15.329L4.968,15.863L5.102,14.968Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M23.438,21.703L23.076,21.57L23.88,15.89L23.17,15.636L22.741,16.077L22.715,16.291L23.116,16.438L22.433,21.316L22.058,21.182L21.71,21.062L22.38,16.331L21.924,15.168L21.335,14.954L20.773,15.529L20.853,14.781L20.277,14.567L19.848,15.008L19.822,15.222L20.21,15.355L19.527,20.233L19.139,20.1L18.817,19.979L19.487,15.248L19.045,14.086L18,13.711L17.29,14.447L17.143,15.516L17.853,15.462L17.92,14.954L18.215,14.647L18.603,14.794L18.79,15.275L18.603,16.625L17.585,16.251L16.862,16.999L16.581,18.964L16.594,18.99L17.036,20.126L17.746,20.38L18.268,19.846L18.188,20.541L19.072,20.861L20.451,21.356L20.558,20.581L20.21,20.447L20.773,16.465L21.429,15.783L21.509,15.81L21.697,16.291L21.067,20.754L20.719,20.621L20.612,21.396L21.951,21.877L23.411,22.412L24,22.719L24,21.195L23.719,21.81L23.438,21.703ZM18.255,18.924L17.652,19.538L17.451,19.458L17.264,18.977L17.465,17.534L17.786,17.2L18.456,17.44L18.255,18.924Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M23.317,14.794L23.987,15.035L24,14.954L24,13.685L23.504,13.498L23.317,14.794Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M21.669,23.227L20.786,22.906L20.812,22.706L21.603,22.986L21.643,22.759L20.853,22.465L20.879,22.278L21.75,22.599L21.79,22.358L20.518,21.89L20.357,23L21.629,23.467L21.669,23.227Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M9.067,11.52L8.424,16.064L8.437,16.09L8.879,17.226L9.964,17.614L10.674,16.879L10.875,15.489L10.165,15.542L10.044,16.371L9.75,16.678L9.321,16.518L9.134,16.037L9.375,14.3L10.955,14.874L11.317,12.335L10.861,11.172L9.777,10.785L9.067,11.52ZM10.419,11.881L10.607,12.362L10.406,13.818L9.495,13.484L9.696,12.028L9.991,11.72L10.419,11.881Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M16.473,21.596L17.089,21.81L17.719,20.875L17.33,20.728L16.848,21.463L16.607,20.474L16.165,20.313L16.473,21.596Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M4.674,17.307L4.889,17.173L4.956,16.732L4.032,16.398L4.005,16.625L4.527,16.812L4.5,16.959L4.474,16.972L3.804,16.732L3.737,16.612L3.791,16.184L3.898,16.13L4.554,16.371L4.581,16.411L4.567,16.465L4.969,16.598L4.982,16.465L4.822,16.197L3.67,15.77L3.416,15.93L3.322,16.558L3.509,16.879L4.674,17.307Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M14.572,10.384L14.947,10.518L13.862,18.229L13.473,18.095L13.366,18.857L14.773,19.365L14.88,18.603L14.531,18.469L15.094,14.487L15.75,13.818L15.831,13.845L16.005,14.313L15.389,18.777L15.027,18.656L14.92,19.418L16.286,19.912L16.393,19.151L16.045,19.017L16.714,14.3L16.273,13.137L15.683,12.923L15.255,13.377L15.777,9.595L15.308,9.422L14.598,10.157L14.572,10.384Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M12.08,18.83L11.826,18.99L11.732,19.632L11.919,19.953L13.031,20.354L13.286,20.193L13.379,19.552L13.192,19.231L12.08,18.83ZM12.911,19.926L12.803,19.993L12.227,19.779L12.147,19.645L12.201,19.244L12.308,19.177L12.897,19.391L12.977,19.512L12.911,19.926Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M10.688,6.495L10.581,7.27L11.987,7.778L12.094,7.003L11.746,6.882L12.308,2.9L12.964,2.218L13.032,2.245L13.219,2.726L12.589,7.19L12.241,7.056L12.134,7.831L13.5,8.326L13.607,7.551L13.259,7.43L13.929,2.7L13.473,1.537L12.898,1.336L12.456,1.777L12.67,0.267L12.014,0L11.076,6.629L10.688,6.495Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M23.732,11.253C23.678,11.547 23.786,11.881 24,12.121L24,11.814C23.919,11.667 23.879,11.493 23.919,11.333C23.933,11.253 23.96,11.186 24,11.132L24,10.825C23.866,10.918 23.772,11.065 23.732,11.253Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M22.902,5.8L23.263,5.934L22.554,7.457L22.353,5.6L22.768,5.76L22.875,4.971L21.469,4.464L21.362,5.252L21.656,5.346L21.978,7.898L20.946,10.21L20.652,10.103L20.29,9.97L21.094,4.29L20.384,4.036L19.955,4.477L19.929,4.691L20.33,4.838L19.647,9.716L19.272,9.582L18.924,9.462L19.594,4.731L19.138,3.568L18.549,3.354L17.987,3.929L18.067,3.181L17.491,2.967L17.062,3.408L17.009,3.662L17.397,3.795L16.714,8.687L16.326,8.553L16.004,8.419L16.674,3.688L16.219,2.539L15.174,2.165L14.464,2.9L14.317,3.969L15.027,3.916L15.094,3.408L15.388,3.1L15.777,3.247L15.964,3.729L15.777,5.078L14.759,4.704L14.036,5.453L13.754,7.417L13.768,7.444L14.21,8.58L14.92,8.834L15.442,8.299L15.362,8.994L16.246,9.315L17.625,9.809L17.732,9.034L17.384,8.9L17.946,4.918L18.603,4.236L18.683,4.263L18.871,4.744L18.241,9.208L17.893,9.074L17.786,9.849L19.125,10.33L20.585,10.865L21.897,11.346L22.004,10.571L21.643,10.437L22.379,8.727L22.634,10.798L22.205,10.638L22.098,11.413L23.451,11.907L23.558,11.132L23.317,11.039L22.955,8.165L23.96,6.147L24.027,6.174L24.027,5.359L23.049,4.998L22.902,5.8ZM15.455,7.337L14.853,7.952L14.652,7.885L14.464,7.404L14.665,5.96L14.987,5.626L15.656,5.867L15.455,7.337Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M8.384,5.493L8.813,6.629L9.83,6.989L10.54,6.254L10.768,4.691L10.058,4.744L9.911,5.747L9.616,6.054L9.255,5.92L9.067,5.439L9.643,1.417L9.938,1.123L10.299,1.256L10.487,1.724L10.339,2.726L11.049,2.673L11.183,1.711L10.741,0.548L10.701,0.535L8.96,1.256L8.371,5.466L8.384,5.493Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M14.304,10.224L14.92,9.275L14.531,9.141L14.049,9.863L13.821,8.874L13.379,8.713L13.687,9.996L14.304,10.224Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M3.977,3.982L3.843,4.878L5.33,5.412L5.45,4.517L5.022,4.357L5.236,2.78L4.513,3.087L4.366,4.129L3.977,3.982Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M0.723,5.292L1.875,5.706L2.103,5.573L2.156,5.132L1.245,4.798L1.205,5.025L1.741,5.225L1.714,5.359L1.687,5.386L1.018,5.145L0.937,5.012L1.004,4.597L1.098,4.53L1.768,4.771L1.795,4.811L1.781,4.865L2.183,5.012L2.196,4.878L2.036,4.597L1.406,4.37L0.576,4.704L0.536,4.971L0.723,5.292Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M10.245,8.753L10.5,8.593L10.594,7.952L10.406,7.644L9.295,7.243L9.04,7.39L8.946,8.032L9.134,8.353L10.245,8.753ZM9.415,7.644L9.522,7.577L10.112,7.791L10.178,7.925L10.125,8.326L10.031,8.393L9.428,8.179L9.361,8.059L9.415,7.644Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M23.023,13.391L23.237,13.244L23.277,12.95L23.143,12.709L22.219,12.375L22.206,12.348L22.219,12.215L22.246,12.188L22.902,12.429L22.929,12.469L22.915,12.522L23.317,12.669L23.331,12.536L23.183,12.282L22.058,11.867L21.844,12.001L21.79,12.308L21.938,12.549L22.862,12.883L22.875,12.896L22.848,13.043L22.822,13.057L22.165,12.816L22.139,12.776L22.139,12.723L21.75,12.589L21.737,12.723L21.884,12.977L23.023,13.391Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M2.21,4.036L1.889,4.17L2.183,4.277L2.21,4.036Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M5.652,4.504L6.081,5.626L7.165,6.027L7.889,5.292L8.076,3.889L7.366,3.942L7.259,4.784L6.965,5.092L6.523,4.931L6.335,4.45L6.59,2.7L8.17,3.274L8.424,1.47L7.674,1.777L7.621,2.218L7.085,2.018L5.92,2.512L5.639,4.477L5.652,4.504Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M18.884,11.627L18,11.306L18.027,11.105L18.817,11.4L18.857,11.159L18.067,10.865L18.094,10.678L18.964,10.999L19.004,10.758L17.732,10.304L17.571,11.413L18.844,11.867L18.884,11.627Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M3.255,4.664L3.67,3.435L2.772,3.795L2.799,4.49L3.255,4.664Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M6.161,6.963L5.411,6.695L5.532,5.867L5.13,5.72L4.969,6.829L6.121,7.243L6.161,6.963Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M5.438,24.055L4.728,24.109L4.607,24.937L4.326,25.258L3.884,25.098L3.696,24.617L3.938,22.866L5.518,23.441L5.879,20.901L5.438,19.739L4.353,19.351L3.643,20.086L3,24.63L3.013,24.657L3.348,25.525L4.647,26.06L5.25,25.432L5.438,24.055ZM4.272,20.594L4.567,20.287L4.996,20.447L5.183,20.928L4.982,22.385L4.071,22.051L4.272,20.594Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M13.581,22.706L12.536,22.331L11.826,23.066L11.679,24.136L12.389,24.082L12.456,23.574L12.75,23.267L13.139,23.414L13.326,23.895L13.139,25.245L12.121,24.871L11.398,25.619L11.116,27.583L11.13,27.61L11.572,28.746L12.268,29L12.295,28.987L12.804,28.465L12.764,28.786L13.38,28.532L14.036,23.855L13.581,22.706ZM12.817,27.503L12.214,28.118L12.014,28.038L11.826,27.557L12.027,26.113L12.348,25.779L13.018,26.02L12.817,27.503Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M8.129,24.857L7.419,24.924L7.272,25.913L6.977,26.22L6.616,26.087L6.428,25.606L7.004,21.583L7.299,21.289L7.661,21.423L7.848,21.89L7.701,22.893L8.411,22.839L8.544,21.877L8.102,20.714L7.085,20.354L6.375,21.089L5.732,25.632L5.745,25.659L6.147,26.701L7.219,27.142L7.902,26.421L8.129,24.857Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M0.737,24.47L2.424,19.391L1.728,24.296L1.339,24.149L1.259,24.683L2.732,25.285L2.813,24.683L2.384,24.523L3.268,18.229L3.696,18.389L3.83,17.494L2.424,16.986L0.683,22.532L0.616,16.331L0,16.104L0,19.538L0.147,24.216L0.737,24.47Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M20.304,25.686L21,25.405L20.384,25.178L20.304,25.686Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M18.75,22.318L18.08,22.077L17.893,23.374L18.562,23.614L18.75,22.318Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M10.259,21.503L9.817,21.944L10.353,18.162L9.884,17.988L9.174,18.736L9.134,18.95L9.522,19.084L8.437,26.795L8.049,26.661L7.942,27.436L9.348,27.944L9.455,27.169L9.107,27.049L9.67,23.066L10.326,22.385L10.393,22.412L10.58,22.893L9.951,27.356L9.603,27.223L9.495,27.998L10.862,28.492L10.969,27.717L10.621,27.597L11.29,22.866L10.835,21.717L10.259,21.503Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M16.473,23.761L15.897,23.547L15.335,24.122L15.415,23.374L14.839,23.16L14.411,23.614L14.371,23.828L14.759,23.975L14.156,28.225L14.906,27.918L15.295,25.111L15.964,24.43L16.031,24.456L16.219,24.937L15.857,27.517L16.594,27.209L16.929,24.911L16.473,23.761Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M18.844,24.617L18.723,25.405L19.018,25.512L19.098,26.18L19.728,25.926L19.701,25.766L19.915,25.846L20.156,25.753L20.25,25.124L18.844,24.617Z" style="fill-rule:nonzero;"/>
        </g>
        <g transform="matrix(1,0,0,1,-11.3045,3.07127)">
            <path d="M17.732,24.216L17.291,24.657L17.264,24.884L17.666,25.031L17.398,26.889L18.148,26.581L18.442,24.47L17.732,24.216Z" style="fill-rule:nonzero;"/>
        </g>
    </g>
</svg></div></div>`);
    if ($target.get(0).tagName === 'IMG') {
        $target.after($veil);
        $veil.css({ width: $target.width(), height: $target.height() });
        if ($target.parent().css('position') === 'static') {
            $target.parent().css('position', 'relative');
        }
    } else {
        $target.append($veil);
        if ($target.css('position') === 'static') {
            $target.parent().css('position', 'relative');
            $target.parent().addClass('veiled');
        }
        if ($target.get(0).tagName === 'BODY') {
            $veil.find('.hex-loader').css('position', 'fixed');
        }
    }
    $veil.click(function (e) {
        e.stopPropagation();
    });
}

/**
 * Remove existing spinner
 * @param  {element} $veil - jQuery pointer to the veil element
 */
function removeSpinner($veil) {
    if ($veil.parent().hasClass('veiled')) {
        $veil.parent().css('position', '');
        $veil.parent().removeClass('veiled');
    }
    $veil.off('click');
    $veil.remove();
}

// element level spinner:
$.fn.spinner = function () {
    var $element = $(this);
    var Fn = function () {
        this.start = function () {
            if ($element.length) {
                addSpinner($element);
            }
        };
        this.stop = function () {
            if ($element.length) {
                var $veil = $('.veil');
                removeSpinner($veil);
            }
        };
    };
    return new Fn();
};

// page-level spinner:
$.spinner = function () {
    var Fn = function () {
        this.start = function () {
            addSpinner($('body'));
        };
        this.stop = function () {
            removeSpinner($('.veil'));
        };
    };
    return new Fn();
};
