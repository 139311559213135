'use strict';
var modal = require('core/components/modal');

/**
 * Generates the modal window on the first call.
 *
 */
modal.getModalHtmlElement = function(elementID, dialogClasses) {
    var id = elementID || 'customModal';
    var qvPDPLink = (id == 'quickViewModal') ? '    <div class="quickview-title h6 font--primary font-weight-bold pt-2"></div>' : '';
    dialogClasses = dialogClasses || '';

    if ($('#' + id).length !== 0) {
        $('#' + id).remove();
    }
    var htmlString = '<div class="modal fade" id="' + id + '" role="dialog">'
        + '<span class="enter-message sr-only"></span>'
        + '<div class="modal-dialog ' + dialogClasses + '">'
        + '<!-- Modal content-->'
        + '<div class="modal-content">'
        + '<div class="modal-header">'
        + qvPDPLink
        + '    <button type="button" class="close pull-right" data-dismiss="modal">'
        + '        <span aria-hidden="true">&times;</span>'
        + '        <span class="sr-only"> </span>'
        + '    </button>'
        + '</div>'
        + '<div class="modal-body"></div>'
        + '<div class="modal-footer"></div>'
        + '</div>'
        + '</div>'
        + '</div>';
    $('body').append(htmlString);
};

module.exports = modal;
